import axios from 'axios';
import { getCookie, removeUserCookies } from './cookieHelper';



const configuration = axios.create({
    baseURL: import.meta.env.VITE_URL_BACKEND,
    // baseURL: '/api',
    withCredentials: true,  // Assicura che i cookie vengano inviati con le richieste
});



configuration.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error?.response?.data?.message === "Token is expired" && error?.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const userId = getCookie('userId');
            if (!userId) {
                removeUserCookies();
                navigate('/login');
                return;
            }
            try {
                await configuration.post('auth/refreshtoken', {}, { withCredentials: true });
                return configuration(originalRequest);
            } catch (refreshError) {
                if (refreshError?.response?.data?.message === "RefreshToken is expired") {
                    removeUserCookies();
                    window.location.href = '/login';
                }
                return Promise.reject(refreshError);
            }
        }
        if (error?.response?.data?.message === "Token is required" && error?.response?.status === 401) {
            removeUserCookies();
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default configuration;