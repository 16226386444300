import { useMutation, useQuery, useQueryClient } from "react-query";
import { removeUserCookies, checkAuthTokenFn } from "../utils/cookieHelper";
import axios from "../utils/axios";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "../utils/constants";
import { useNavigate } from "react-router-dom";

const getCurrentStudent = async () => {
    checkAuthTokenFn();
    const response = await axios.get(`/student`);
    return response.data;
};

export const useGetCurrentStudent = () => {
    const navigate = useNavigate();
    const { data, isLoading } = useQuery(['/student'], getCurrentStudent, {
        retry: false,
        onError: (error) => {
            if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
                toast.error(ERROR_MESSAGES["ERR_NETWORK"]);
                return;
            }
            if (error?.response?.data?.code === 401 || error?.response?.data?.code === 403) {
                removeUserCookies();
                navigate('/login');
                return;
            }
            toast.error(ERROR_MESSAGES[error?.response?.data?.code], { duration: 7000 });
        },
        staleTime: 24 * 60 * 60 * 1000,
    });

    return {
        student: data,
        Student: isLoading,
    };
};


const getAllClassroomByStudent = async () => {
    checkAuthTokenFn();
    const response = await axios.get(`/classroom/student`);
    return response.data;
};

export const useGetAllClassroomByStudent = () => {
    const navigate = useNavigate();
    const { data, isLoading } = useQuery(['/classrooms'], getAllClassroomByStudent, {
        retry: false,
        onError: (error) => {
            if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
                toast.error(ERROR_MESSAGES["ERR_NETWORK"]);
                return;
            }
            if (error?.response?.data?.code === 401 || error?.response?.data?.code === 403) {
                removeUserCookies();
                navigate('/login');
                return;
            }
            toast.error(ERROR_MESSAGES[error?.response?.data?.code], { duration: 7000 });
        },
        staleTime: 24 * 60 * 60 * 1000,
    });

    return {
        response: data,
        isLoading,
    };
};

const getClassroomForStudentById = async id => {
    checkAuthTokenFn();
    const response = await axios.get(`/classroom/${id}/student`);
    return response.data;
};

export const useGetClassroomForStudentById = (id) => {
    const navigate = useNavigate();

    const { data, isLoading } = useQuery(['/classrooms', id], () => getClassroomForStudentById(id), {
        retry: false,
        onError: (error) => {
            if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
                toast.error(ERROR_MESSAGES["ERR_NETWORK"]);
                return;
            }

            if (
                (error.response.data.message.includes(`Classroom not found`) && error.response.data.code === 404)
                ||
                (error.response.data.message.includes(`Value of ID ${id} is incorrect ObjectId`) && error.response.data.code === 400)
            ) {
                toast(ERROR_MESSAGES["ERR_ID_CLASSROOM"], {
                    icon: '⚠️',
                    duration: 5000,
                });
                navigate('/dashboard?tab=classroom');
                return;
            }
            if (error.response.data.message === 'You are not enrolled in this classroom' && error.response.data.code === 403) {
                toast(ERROR_MESSAGES["ERR_USER_NOT_EROLLED_IN_CLASSROOM"], {
                    icon: '⚠️',
                    duration: 5000,
                });
                navigate('/dashboard?tab=classroom');
                return;
            }
            toast.error(ERROR_MESSAGES[error.response.data.code], { duration: 7000 });
        },
        staleTime: 24 * 60 * 60 * 1000,
    });

    return {
        response: data,
        isLoading,
    };
};


const addStudentToClassroom = async (content) => {
    checkAuthTokenFn();
    const resp = await axios.post("/classroom/student", content);
    return resp.data;
};

export const useAddStudentToClassroom = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { mutateAsync, isLoading } = useMutation(addStudentToClassroom, {
        retry: false,
        onSuccess: () => {
            queryClient.invalidateQueries('/classrooms');
            toast.success("Studente aggiunto con successo!");
        },
        onError: async (error) => {
            if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
                toast.error(ERROR_MESSAGES["ERR_NETWORK"]);
                return;
            }
            if (error?.response?.data?.message === 'Student is already in the classroom') {
                toast(ERROR_MESSAGES["ERR_EXIST_STUDENT"], {
                    icon: '⚠️',
                    duration: 5000,
                });
                return;
            }
            if (error?.response?.data?.message === 'Classroom not found') {
                toast(ERROR_MESSAGES["ERR_NOT_FOUND_CLASSROOM"], {
                    icon: '⚠️',
                    duration: 5000,
                });
                return;
            }
            if (error?.response?.data?.message === 'The classroom has reached the maximum number of students.') {
                toast(ERROR_MESSAGES["ERR_CLASSROOM_FULL"], {
                    icon: '⚠️',
                    duration: 5000,
                });
                return;
            }
            if (error?.response?.data?.code === 401 || error?.response?.data?.code === 403) {
                removeUserCookies();
                navigate('/login');
                return;
            }
            toast.error(ERROR_MESSAGES[error.response.data.code], { duration: 7000 });
        },
    });
    return {
        addStudent: mutateAsync,
        isAdditing: isLoading,
    };
};

const addResolvedQuizToStudent = async (content) => {
    try {
        const resp = await axios.post("/student/resolvedquiz", content);
        return resp.data;
    } catch (error) {
        throw error;
    }

};

export const useAddResolvedQuizToStudent = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { mutateAsync, isLoading } = useMutation(addResolvedQuizToStudent, {
        retry: false,
        onSuccess: () => {
            queryClient.invalidateQueries('/student');
            queryClient.invalidateQueries('/classrooms');
            toast.success("Risultato del Quiz aggiunto con successo!");
        },
        onError: async (error) => {
            if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
                toast.error(ERROR_MESSAGES["ERR_NETWORK"]);
                return;
            }
            if (error?.response?.data?.message === 'Quiz is already resolved') {
                queryClient.invalidateQueries('/student');
                queryClient.invalidateQueries('/classrooms');
                toast(ERROR_MESSAGES["ERR_QUIZ_ALREADY_RESOLVED"], {
                    icon: '⚠️',
                    duration: 4000,
                });
                navigate('/dashboard?tab=quiz');
                return;
            }
            if (error?.response?.data?.code === 401 || error?.response?.data?.code === 403) {
                removeUserCookies();
                navigate('/login');
                return;
            }
            toast.error(ERROR_MESSAGES[error.response.data.code], { duration: 7000 });
        },
    });
    return {
        addResolvedQuiz: mutateAsync,
        isAdditingResolvedQuiz: isLoading,
    };
};

const deleteStudentFromClassroom = async ({ idClassroom, idStudent }) => {
    checkAuthTokenFn();
    const resp = await axios.delete(`/classroom/${idClassroom}/student/${idStudent}`);
    return resp.data;
};

export const useDeleteStudentFromClassroom = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { mutateAsync, isLoading } = useMutation(deleteStudentFromClassroom, {
        retry: false,
        onSuccess: () => {
            queryClient.invalidateQueries('/classrooms');
            toast.success('Studente rimosso con successo');
        },
        onError: async (error) => {
            if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
                toast.error(ERROR_MESSAGES["ERR_NETWORK"]);
                return;
            }
            if (error?.response?.data?.code === 401 || error?.response?.data?.code === 403) {
                removeUserCookies();
                navigate('/login');
                return;
            }
            if (error?.response?.data?.message === 'Classroom not found'
                ||
                error?.response?.data?.message === 'Student not found in the classroom') {
                toast(ERROR_MESSAGES["ERR_NOT_FOUND_CLASSROOM_OR_STUDENT"], {
                    icon: '⚠️',
                    duration: 5000,
                });
                return;
            }
            toast.error(ERROR_MESSAGES[error?.response?.data?.code], { duration: 7000 });
        },
    });

    return {
        deleteStudent: mutateAsync,
        isDeleting: isLoading,
    };
};

const addQuizToStudent = async (content) => {
    const resp = await axios.post("/student/quiz", content);
    return resp.data;
};

export const useAddQuizToStudent = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { mutateAsync, isLoading } = useMutation(addQuizToStudent, {
        retry: false,
        onSuccess: () => {
            queryClient.invalidateQueries('/student');
            toast.success("Quiz aggiunto nell tuo account con successo!");
        },
        onError: async (error) => {
            if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
                toast.error(ERROR_MESSAGES["ERR_NETWORK"]);
                return;
            }
            // if (error?.response?.data?.message === 'You have reached the maximum limit of quiz') {
            //     toast(ERROR_MESSAGES["ERR_REACHED_MAX_LIMIT_QUIZ"], {
            //         icon: '⚠️',
            //         duration: 5000,
            //     });
            //     navigate('/dashboard?tab=quiz');
            //     return;
            // }

            if (error?.response?.data?.code === 401 || error?.response?.data?.code === 403) {
                removeUserCookies();
                navigate('/login');
                return;
            }
            toast.error(ERROR_MESSAGES[error.response.data.code], { duration: 7000 });
        },
    });
    return {
        addQuiz: mutateAsync,
        isAdditingQuiz: isLoading,
    };
};

const deleteQuizFromStudent = async (idQuiz) => {
    checkAuthTokenFn();
    const resp = await axios.delete(`/student/quiz/${idQuiz}`);
    return resp.data;
};

export const useDeleteQuizFromStudent = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { mutateAsync, isLoading } = useMutation(deleteQuizFromStudent, {
        retry: false,
        onSuccess: () => {
            queryClient.invalidateQueries('/student');
            toast.success('Quiz rimosso con successo');
        },
        onError: async (error) => {
            if (error?.code === 'ERR_NETWORK' || error?.code === "ERR_BAD_RESPONSE") {
                toast.error(ERROR_MESSAGES["ERR_NETWORK"]);
                return;
            }
            if (error?.response?.data?.code === 401 || error?.response?.data?.code === 403) {
                removeUserCookies();
                navigate('/login');
                return;
            }
            if (error?.response?.data?.message === 'Quiz not found in the quizList') {
                toast(ERROR_MESSAGES["ERR_NOT_FOUND_QUIZ"], {
                    icon: '⚠️',
                    duration: 5000,
                });
                return;
            }
            toast.error(ERROR_MESSAGES[error?.response?.data?.code], { duration: 7000 });
        },
    });

    return {
        deleteQuizFromStudent: mutateAsync,
        isDeletingQuiz: isLoading,
    };
};