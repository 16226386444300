import { useAuth } from '../context/authContext';
import { Navigate} from 'react-router-dom';
import { useGetCurrentStudent } from '../hooks/useStudent';
import { useEffect, useState } from 'react';
import { Spinner } from './Spinner';

export const ProtectedRoute = ({ element}) => {
  const { isAuthenticated } = useAuth();
  const { student, isLoadingStudent } = useGetCurrentStudent();

  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    if (student && !isLoadingStudent) {
      setIsLoading(false);
    }   
  }, [student, isLoadingStudent]);

  if (isLoading) {  
    return <Spinner/>
  }

  return isAuthenticated && !isLoading ? element :  <Navigate to="/login" /* state={{ from: location.pathname }} replace *//>
};
